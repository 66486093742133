import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import client from '../../api';
import { NumericFormat } from 'react-number-format';

const DetailInvoice = ({ detailInvoice, onClose }) => {
  const { user } = useSelector(state => state.auth);
  const [invoiceDetail, SetInvoiceDetail] = useState([]);

  const checkStatusInvoice = (paid_value, total_invtax, ar_overdue) => {
    if (paid_value === total_invtax && ar_overdue === 'Done') {
      return <span className='btn bg-[#3E1C86] text-white '>lunas</span>;
    }

    if (!paid_value && ar_overdue <= 0) {
      return <span className='btn bg-[#CD2073] text-white'>Belum Bayar</span>;
    }

    if (!paid_value && ar_overdue > 0) {
      return <span className='btn bg-red-900 text-white'>Overdue</span>;
    }

    if (parseFloat(paid_value) < parseFloat(total_invtax)) {
      return <span className='btn bg-[#FFA500] text-white '>Partial</span>;
    }
  };

  const getInvoiceDetail = async () => {
    const response = await client.get(
      `/api/customers/invoice/${detailInvoice.id}`,
      {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${user.data.token}`
        }
      }
    );
    SetInvoiceDetail(response.data);
  };

  useEffect(() => {
    getInvoiceDetail();
  }, []);

  return (
    <div>
      <div className='flex justify-between p-1 mb-2'>
        <div className='text-left w-2/3'>
          <div className='text-gray-400 text-xs'>No. Invoice</div>
          <div className='text-sm font-semibold'>{detailInvoice.inv_no}</div>
        </div>
        <div className='text-right w-1/3'>
          <div className='text-gray-400 text-xs'>Status</div>
          <div className='text-sm font-semibold'>
            {checkStatusInvoice(
              detailInvoice.paid_value,
              detailInvoice.total_invtax,
              detailInvoice.ar_overdue
            )}
          </div>
        </div>
      </div>
      <div>
        <div className='scroll-smooth scrollbar-hide overflow-y-auto max-h-72'>
          <table className='table-auto w-full'>
            <thead className='text-[13px] bg-gray-100 sticky top-0'>
              <tr className='text-center '>
                <th className=' w-[3%] '>No</th>
                <th className=' w-[10%]'>Tanggal</th>
                <th className='w-[60%]'>Deskripsi</th>
                <th className='w-[15%]'>Harga</th>
                <th className='w-[15%] '>No.SO#</th>
              </tr>
            </thead>
            <tbody className='bg-white border border-gray-100 text-xs '>
              {invoiceDetail.map((row, i) => (
                <tr
                  key={i}
                  className={`align-top border border-gray-200 ${
                    i % 2 === 0 ? `bg-slate-100` : ''
                  }`}>
                  <td className='text-center'>{i + 1}</td>
                  <td className='text-center'>
                    {moment(row.tgl_order).format('DD-MM-YYYY')}
                  </td>
                  <td>{row.inv_desc}</td>
                  <td className='text-right'>
                    <NumericFormat
                      value={parseInt(row.inv_harga ?? 0)}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'Rp '}
                      renderText={value => <p>{value}</p>}
                    />
                  </td>
                  <td>{row.so_number}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className='flex justify-end py-3'>
        <div className='modal-close cursor-pointer'>
          <button
            onClick={() => {
              onClose();
            }}
            className='btn border py-2 px-3 bg-slate-100 hover:bg-slate-300 text-md  border-slate-400 '>
            Tutup
          </button>
        </div>
      </div>
    </div>
  );
};

export default DetailInvoice;
