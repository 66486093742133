import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import profileService from './profileService';

const initialState = {
  profile: null,
  isLoading: false,
  isSuccess: false,
  isError: false,
  message: '',
  profileFile: null,
  isLoadingUpload: false,
  isSuccessUpload: false,
  isErrorUpload: false,
  messageUpload: '',
  isLoadingUpdatePassword: false,
  isSuccessUpdatePassword: false,
  isErrorUpdatePassword: false,
  messageUpdatePassword: ''
};

export const profileCustomer = createAsyncThunk(
  'auth/profile',
  async (user, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.data.token;

      return await profileService.getProfile(token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.error.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const uploadCustomerPhoto = createAsyncThunk(
  'auth/upload',
  async (file, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.data.token;

      return await profileService.uploadPhoto(file, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.error.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const passwordUpdate = createAsyncThunk(
  'auth/password-update',
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.data.token;
      return await profileService.passwordUpdate(data, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.error.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    reset: state => initialState,
    resetUpload: state => {
      state.profileFile = null;
      state.isLoadingUpload = false;
      state.isSuccessUpload = false;
      state.isErrorUpload = false;
      state.messageUpload = '';
    },
    resetUpdatePassword: state => {
      state.isLoadingUpdatePassword = false;
      state.isSuccessUpdatePassword = false;
      state.isErrorUpdatePassword = false;
      state.messageUpdatePassword = '';
    }
  },
  extraReducers: builder => {
    builder
      .addCase(profileCustomer.pending, state => {
        state.isLoading = true;
      })
      .addCase(profileCustomer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.profile = action.payload;
      })
      .addCase(profileCustomer.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(passwordUpdate.pending, state => {
        state.isLoadingUpdatePassword = true;
      })
      .addCase(passwordUpdate.fulfilled, (state, action) => {
        state.isLoadingUpdatePassword = false;
        state.isErrorUpdatePassword = false;
        state.isSuccessUpdatePassword = true;
      })
      .addCase(passwordUpdate.rejected, (state, action) => {
        state.isLoadingUpdatePassword = false;
        state.isErrorUpdatePassword = true;
        state.isSuccessUpdatePassword = false;
        state.messageUpdatePassword = action.payload;
      })
      .addCase(uploadCustomerPhoto.pending, state => {
        state.isLoadingUpload = true;
      })
      .addCase(uploadCustomerPhoto.fulfilled, (state, action) => {
        state.isLoadingUpload = false;
        state.isErrorUpload = false;
        state.isSuccessUpload = true;
        state.profileFile = false;
      })
      .addCase(uploadCustomerPhoto.rejected, (state, action) => {
        state.isErrorUpload = true;
        state.isSuccessUpload = false;
        state.isLoadingUpload = false;
        state.messageUpload = action.payload;
      });
  }
});

export const { reset, resetUpload, resetUpdatePassword } = profileSlice.actions;
export default profileSlice.reducer;
