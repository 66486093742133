import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: true,
  status: 'idle'
};

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    wrap: state => {
      state.value = false;
    },
    unwarp: state => {
      state.value = true;
    }
  }
});

export const { wrap, unwarp } = sidebarSlice.actions;

export const sidebarState = state => state.sidebar.value;

export default sidebarSlice.reducer;
