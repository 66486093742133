import React, { useState } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useSelector } from 'react-redux';
import client from '../../api';

const MySwal = withReactContent(Swal);

const FormUploadPo = props => {
  const { user } = useSelector(state => state.auth);
  const { orderId, onClose, refresh } = props;
  const [poNumber, setPoNumber] = useState('');
  const [poFile, setPoFile] = useState('');
  const [poNote, setPoNote] = useState('');
  const savePo = async e => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('po_number', poNumber);
    formData.append('po_file', poFile);
    formData.append('po_note', poNote);
    try {
      const order = await client.patch(
        `/api/customers/orders/${orderId}/upload-po`,
        formData,
        {
          headers: {
            'Content-Type': 'mulitpart/form-data',
            Authorization: `Bearer ${user.data.token}`
          }
        }
      );
      if (order.status === 200) {
        MySwal.fire({
          title: <strong>Berhasil!</strong>,
          html: <i>File PO berhasil di upload!</i>,
          icon: 'success'
        });
        onClose();
        refresh();
      }
    } catch (error) {
      return Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.response.data.msg
      });
      //   return alert(error);
    }
  };

  return (
    <div>
      <form className='w-full justify-center content-center' onSubmit={savePo}>
        <div className='md:flex md:items-center mb-6'>
          <div className='md:w-1/3'>
            <label
              className='block  font-bold  mb-1 md:mb-0 pr-4'
              htmlFor='inline-full-name'>
              PO Number
            </label>
          </div>
          <div className='md:w-2/3'>
            <input
              className=' border-2 border-gray-300 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500'
              id='inline-full-name'
              type='text'
              value={poNumber}
              onChange={e => setPoNumber(e.target.value)}
              required
            />
          </div>
        </div>
        <div className='md:flex md:items-center mb-6'>
          <div className='md:w-1/3'>
            <label
              className='block  font-bold  mb-1 md:mb-0 pr-4'
              htmlFor='inline-full-name'>
              PO File
            </label>
          </div>
          <div className='md:w-2/3'>
            <input
              className='block w-full text-sm text-gray-900  border-2 border-gray-300  rounded-md cursor-pointer  dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400'
              id='file_input'
              type='file'
              onChange={e => setPoFile(e.target.files[0])}
              required
            />
            <div className='text-right text-sm font-medium'>
              <small>*format file jpg, jpeg, png, pdf</small>
            </div>
          </div>
        </div>
        <div className='md:flex md:items-center mb-6'>
          <div className='md:w-1/3'>
            <label
              className='block font-bold  mb-1 md:mb-0 pr-4'
              htmlFor='inline-full-name'>
              PO Note
            </label>
          </div>
          <div className='md:w-2/3'>
            <textarea
              id='message'
              rows='4'
              className='border-2 border-gray-300 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500'
              placeholder=''
              onChange={e => setPoNote(e.target.value)}
              required></textarea>
          </div>
        </div>
        <div className='md:flex md:items-center justify-between'>
          <div className='md:w-3/3'>
            <button
              className='btn border py-2 px-3 bg-slate-100 hover:bg-slate-300 text-md border-slate-400'
              type='button'
              onClick={() => {
                onClose();
              }}>
              Tutup
            </button>
          </div>
          <div className='md:w-3/3'>
            <button
              className='btn border py-2 px-3  bg-purple-500 hover:bg-purple-400 text-md text-white border-slate-400'
              type='submit'>
              Upload
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default FormUploadPo;
