/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect } from 'react'
import client from '../../api';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { ReactComponent as MarkerIcon } from '../../svg/lokasi_bongkar.svg';
import { ReactComponent as LokasiMuat } from '../../svg/lokasi-muat.svg';
import { ReactComponent as NoData } from '../../svg/no-data.svg';
import { ReactComponent as FilterNull } from '../../svg/filter-null.svg';

const JadwalKapal = ({ jadwalKapal, isFilter }) => {
    const jadwalVessel = Object.keys(jadwalKapal?.data);
    const legend = ['ETD (Perkiraan waktu keberangkatan)', 'ETA (Perkiraan waktu kedatangan)', 'Tidak ada jadwal keberangkatan']
    const legendIcon = {
        'ETD (Perkiraan waktu keberangkatan)': '#006C9C',
        'ETA (Perkiraan waktu kedatangan)': '#118D57',
        'Tidak ada jadwal keberangkatan': '#EA040480',
    }
    const [width, setWidth] = useState(100);
    const [widthTitle, setWidthTitle] = useState(100);


    useEffect(() => {
        const resizeObserver = new ResizeObserver((event) => {
            setWidthTitle(event[0].contentBoxSize[0].inlineSize);
        });

        resizeObserver.observe(document.getElementById("title-jadwal"));
    });

    const NoDataView = () => {
        return (
            <tr
                className='border-t border-l border-solid border-gray-300'
            >
                <td colSpan={3}
                    align={'center'}
                >
                    {!isFilter ?

                        <>
                            <NoData />
                            <p>
                                Maaf, jadwal kapalnya belum bisa dilihat sekarang.
                                Tungguin info selanjutnya ya!!!
                            </p>
                        </>
                        :
                        <>
                            <FilterNull />
                            <p>
                                Ooops, jadwal kapal yang kamu cari tidak ada
                            </p>
                        </>
                    }

                </td>

            </tr>

        )
    }


    return (
        <div style={{
            overflowX: "auto",
        }}>
            <table className='border w-full'>
                <thead >
                    <tr>
                        <td
                            style={{
                                width: jadwalVessel?.length == 0 ? '100px' : 'auto'
                            }}
                            id="title-jadwal"
                            align='center'
                            className='
                            font-semibold
                            text-l 
                            leading-5
                            p-0
                            border-b
                            border-t
                            border-l 
                            border-solid
                          border-gray-300
                        '
                            rowSpan={1}>
                            <p>Rute</p>
                        </td>
                        <td
                            className='pl-0 pr-0'
                            colSpan={jadwalVessel?.length == 0 ? 0 : 2}
                        >
                            <table
                                style={{
                                    width: '100%'
                                }}
                            >
                                <tr>
                                    <td
                                        colSpan={jadwalKapal?.title_etd.length}
                                        className='
                                    font-semibold
                                    text-l 
                                    leading-5
                                    text
                                    border-l border-solid border-gray-300
                                    text-center
                                      pt-[12.6px]
                                            pb-[12.6px]
                                    '
                                    >
                                        ETD-ETA
                                    </td>
                                </tr>
                                <tr
                                    className='border-l border-solid border-gray-300 bg-[#DFE4EA]'
                                    style={{
                                        width: '100%'
                                    }}
                                    id="jadwal-title"

                                >
                                    {jadwalKapal?.title_etd?.map((row, index) => (
                                        <td
                                            align='center'
                                            key={index}
                                            style={{
                                                // width: '236px'
                                            }}
                                            id="title-jadwal"
                                            className='
                                            pt-[12.6px]
                                            pb-[12.6px]
                                            text-[13px]
                                            font-normal
                                               '
                                        >
                                            <p className='  w-[87px]
                                            h-[25px]
                                            rounded-[50px]
                                            bg-[#006C9C]
                                            text-[#fff]
                                            flex
                                            justify-center
                                            items-center'>
                                                {row}

                                            </p>
                                        </td>
                                    ))}
                                </tr>
                            </table>
                        </td>
                    </tr>

                </thead>
                <tbody>
                    {
                        jadwalVessel?.length == 0 ?
                            <NoDataView />
                            :
                            jadwalVessel?.map((row, index) => {
                                var destination = jadwalKapal?.data[row]?.filter((value) => value);
                                return (
                                    <Fragment
                                        key={index}
                                    >
                                        <tr
                                            className='border-t border-l border-solid border-gray-300'
                                            style={{
                                                background: Math.abs(index % 2) === 1 ? '#F9FAFB' : 'auto'
                                            }}
                                        >
                                            <td
                                                align='center'
                                                className='text-[13px] font-normal'
                                            >
                                                {destination[0]?.od}
                                            </td>

                                            <td
                                                colSpan={2}
                                                className='p-0 border-t border-l border-solid border-gray-300'
                                            >
                                                <div style={{
                                                    overflowX: "auto"
                                                }}>
                                                    <table
                                                        style={{
                                                            width: '100%'
                                                        }}
                                                    >
                                                        <tr
                                                            key={index}
                                                            x style={{
                                                                width: '100%'
                                                            }}
                                                        >
                                                            {
                                                                jadwalKapal?.data[row]?.map((schedule, index) => {
                                                                    return (
                                                                        <td
                                                                            align='center'
                                                                            key={index}
                                                                            className='pl-0 pr-0 text-[13px] font-normal'
                                                                            style={{
                                                                                width: width,
                                                                            }}
                                                                        >
                                                                            {schedule !== null ?
                                                                                <p className='
                                                                            w-[87px]
                                                                            h-[25px]
                                                                            rounded-[50px]
                                                                           bg-[#118D57]
                                                                            text-[#fff]
                                                                            flex
                                                                            justify-center
                                                                            items-center
                                                                            '>
                                                                                    {schedule.eta}
                                                                                </p>
                                                                                :
                                                                                <div className='w-[55px] h-[25px] rounded-[50px] bg-[#EA040480]'>
                                                                                </div>
                                                                            }
                                                                        </td>
                                                                    )
                                                                })}

                                                        </tr>
                                                    </table>
                                                </div>
                                            </td>
                                        </tr>
                                    </Fragment>
                                )
                            })
                    }

                </tbody>
            </table >


            <table className=' mt-3 border w-full'>
                <thead>
                    <tr>
                        <td
                            align='center'
                            className={`
                            text-[13px]
                            font-normal
                            leading-5
                            border-b
                            border-t
                            border-l 
                            border-solid
                          border-gray-300
                        `}
                            style={{
                                width: widthTitle
                            }}
                        >
                            <p>Keterangan</p>
                        </td>
                        <td
                            className='pl-0 pr-0'
                            colSpan={2}
                        >

                            <table
                                style={{
                                    width: '100%'
                                }}
                            >

                                <tr
                                    className='border-l border-solid border-gray-300'
                                    style={{
                                        width: '100%'
                                    }}
                                    id="jadwal-title"

                                >
                                    {legend.map((row, index) => (
                                        <td
                                            align='center'
                                            key={index}
                                            style={{
                                                background: index == 0 || index == 2 ? '#F9FAFB' : 'auto'
                                            }}
                                            className='
                                            border-r border-solid
                                            pt-[12.6px] pb-[12.6px] text-[13px] font-normal'
                                        >
                                            <div

                                            >

                                                <p
                                                    className="flex
                                                  gap-[10px]
                                                  items-center
                                                  justify-center
                                                  pl-[10px]
                                                  pr-[10px]
                                                  "
                                                >
                                                    <span
                                                    //     className='
                                                    //  w-[50%]'
                                                    >
                                                        <div className={`
                                                w-[55px]
                                                h-[25px] 
                                                rounded-[50px] 
                                                bg-[${legendIcon[row]}]`}>
                                                        </div>
                                                    </span>
                                                    {row}

                                                </p>
                                            </div>

                                        </td>
                                    ))}
                                </tr>
                            </table>
                        </td>
                        {/* <td>
                            ETA (Perkiraan waktu kedatangan)
                        </td>
                        <td>
                            Tidak ada jadwal keberangkatan
                        </td> */}
                    </tr>
                </thead>
            </table>
        </div >

    )
}


const ScheduleKapal = () => {
    const { user } = useSelector(state => state.auth);
    const [isFilter, setIsFilter] = useState(false)
    // eslint-disable-next-line no-unused-vars
    const [params, setParams] = useState({
        origin: null,
        destination: null,
        rute: null
    });
    const [jadwalKapal, setJadwalKapal] = useState({
        title_etd: [],
        data: [],
    });
    const options = [
        { value: 'Jakarta', label: 'Jakarta' },
        { value: 'Samarinda', label: 'Samarinda' },
        { value: 'Banjarmasin', label: 'Banjarmasin' },
        { value: 'Sampit', label: 'Sampit' },
        { value: 'Pontianak', label: 'Pontianak' },
        { value: 'Makassar', label: 'Makassar' },
        { value: 'Balikpapan', label: 'Balikpapan' },
        { value: 'Medan', label: 'Medan' },
        { value: 'Surabaya', label: 'Surabaya' },
    ];

    const getData = async () => {
        const response = await client.get(
            `/api/customers/ship-schedules`, {
            params,
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${user.data.token}`
            }
        });
        return response.data;
    };



    useEffect(() => {

        getData()
            .then(info => {
                const {
                    data,
                    title_etd,
                } = info;
                setJadwalKapal({
                    data: data,
                    title_etd: title_etd,
                });
            })
            .catch(() => toast.error('Internal Server Error'));
    }, [params]);
    return (
        <div className='card self-start order-first md:order-last'>
            <div className='card-header flex justify-between text-sm'>
                <h4
                    style={{
                        color: '#000000',
                        fontFamily: 'Poppins',
                        fontSize: '16px',
                        fontWeight: '600',
                        lineHeight: '24px'
                    }}
                >
                    Jadwal Keberangkatan Kapal
                </h4>
            </div>
            <div className='card-body '>
                <div className='flex'>
                    <div className='mt-[10px] text-xs text-gray-300  w-3/6 sm:w-full'>
                        <span className='
                    font-semibold
                    text-[13px]
                    leading-4 
                    text-[#000000]'>Origin :</span>
                        <div className='flex mt-2 mb-2'>
                            <span className='rounded-sm inline-flex items-center px-3 text-sm text-gray-900 bg-white  border-r-0 border-2 border-grey-300 '>
                                <LokasiMuat />
                            </span>
                            <Select
                                isClearable={true}
                                className='rounded-sm font-normal bg-green-100   dark:bg-green-900 dark:placeholder:text-green-100 w-full mr-2'
                                placeholder="Silahkan Pilih Origin"
                                onChange={(selectedOption) => {
                                    setParams({
                                        ...params,
                                        origin: selectedOption?.value,
                                        rute: selectedOption == null ? null : params?.rute
                                    })
                                    setIsFilter(selectedOption == null ? false : true)
                                }}
                                options={options}
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderRadius: '0',
                                        border: '1.6px solid',
                                        height: '44px',

                                    }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        color: '#000',
                                    })
                                }}
                            />

                        </div>
                    </div>

                    <div className='mt-[10px] text-xs text-gray-300  w-3/6 sm:w-full'>
                        <span className='
                    font-semibold
                    text-[13px]
                    leading-4 
                    text-[#000000]'>Destination :</span>
                        <div className='flex mt-2 mb-2'>

                            <span className='rounded-sm inline-flex items-center px-3 text-sm text-gray-900 bg-white  border-r-0 border-2 border-grey-300 '>
                                <MarkerIcon />
                            </span>
                            <Select
                                isClearable={true}
                                placeholder="Silahkan Pilih Destination"
                                className='rounded-sm font-normal bg-green-100   dark:bg-green-900 dark:placeholder:text-green-100 w-full mr-2'
                                onChange={(selectedOption) => {
                                    setParams({
                                        ...params,
                                        destination: selectedOption?.value,
                                        rute: selectedOption == null ? null : params?.rute
                                    })
                                    setIsFilter(selectedOption == null ? false : true)
                                }}
                                options={options}
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderRadius: '0',
                                        border: '1.6px solid',
                                        height: '44px',
                                        color: 'black'
                                    }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        color: '#000',
                                    })
                                }}
                            />
                            <div className='flex justify-center items-center w-[35%]'>
                                <input

                                    type="checkbox"
                                    defaultValue={params?.rute}
                                    checked={params?.rute == null || params?.rute == false ? false : true}
                                    onChange={(e) => {

                                        if (params.origin == null && params.destination == null) {
                                            e.preventDefault();
                                            toast.error('Harap pilih Origin & Destination terlebih dahulu!');
                                            return [
                                                false,
                                                e.stopPropagation()
                                            ]
                                        }
                                        setParams({
                                            ...params,
                                            rute: e.target.checked
                                        })
                                        return true

                                    }}
                                    class="form-checkbox h-5 w-5 text-gray-600" /><span class="ml-2 text-gray-700">Rute Bolak-Balik</span>
                            </div>
                        </div>
                    </div>
                </div>

                <JadwalKapal
                    isFilter={isFilter}
                    jadwalKapal={jadwalKapal}
                />
            </div>
        </div>
    )
}

export default ScheduleKapal