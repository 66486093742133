import React from 'react';
import { NavLink } from 'react-router-dom';
import Lottie from 'lottie-react';
import NotfoundLottie from '../../lottie/81122-error-404-page-not-found.json';

const NotFound = () => {
  return (
    <div className='bg-gray-200 w-full px-16 md:px-0 h-screen flex items-center justify-center'>
      <div className='bg-white border border-gray-200 flex flex-col items-center justify-center px-4 md:px-8 lg:px-24 py-8 rounded-lg shadow-2xl'>
        <Lottie animationData={NotfoundLottie} className='h-96 -mt-24' />
        <p className='text-2xl md:text-3xl lg:text-5xl font-bold tracking-wider text-gray-500 -mt-28'>
          Page Not Found
        </p>
        <p className='text-gray-500 mt-4 pb-4 border-b-2 text-center'>
          Sorry, the page you are looking for could not be found.
        </p>
        <NavLink
          to={'/'}
          className='flex items-center space-x-2 bg-blue-600 hover:bg-blue-700 text-gray-100 px-4 py-2 mt-6 rounded transition duration-150'
          title='Return Home'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className='h-5 w-5'
            viewBox='0 0 20 20'
            fill='currentColor'>
            <path
              fill-rule='evenodd'
              d='M9.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L7.414 9H15a1 1 0 110 2H7.414l2.293 2.293a1 1 0 010 1.414z'
              clip-rule='evenodd'></path>
          </svg>
          <span>Return Home</span>
        </NavLink>
      </div>
    </div>
  );
};

export default NotFound;
