import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import client from '../../api';
import { picState } from '../../features/pic/picSlice';

const DataInvoice = ({ filter }) => {
  const { user } = useSelector(state => state.auth);
  const pic = useSelector(picState);
  const [invoceData, setInvoiceData] = useState({
    rowData: [],
    isLoading: false,
    totalPages: 0,
    totalDataAll: 0,
    totalDataPages: 0
  });
  const [currentInvoicePage, setCurrentInvoicePage] = useState(1);

  const getData = async (
    pageNo = 1,
    filter = null,
    pic = null,
    sizePage = 5
  ) => {
    const response = await client.get(
      `/api/customers/dashboard/invoice?page=${pageNo}&size=${sizePage}&filter=${filter}&branch=${pic}`,
      {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${user.data.token}`
        }
      }
    );
    return response.data;
  };
  function isNegative(num) {
    if (num < 0) {
      return true;
    }

    return false;
  }
  const onNextPage = () => setCurrentInvoicePage(currentInvoicePage + 1);
  const onPrevPage = () => setCurrentInvoicePage(currentInvoicePage - 1);
  const refFilter = useRef();
  const refPic = useRef();
  useEffect(() => {
    setInvoiceData(prevState => ({
      ...prevState,
      rowData: [],
      isLoading: true
    }));
    getData(filter === refFilter.current ? currentInvoicePage : 1, filter, pic)
      .then(info => {
        const {
          count,
          rowCount,
          rows,
          totalPages: total_pages,
          currentPage
        } = info;
        setInvoiceData({
          isLoading: false,
          rowData: rows,
          totalPages: total_pages,
          totalDataPages: rowCount,
          totalDataAll: count
        });
        setCurrentInvoicePage(currentPage);
      })
      .catch(() => toast.error('Internal Server Error'));
    refFilter.current = filter;
    refPic.current = pic;
  }, [currentInvoicePage, filter, pic]);



  return (
    <div className='card self-start order-first md:order-last'>
      <div className='card-header flex justify-between text-sm'>
        <div>Invoice</div>
      </div>

      <div className='card-body pt-1 overflow-y-auto max-h-96 scroll-smooth scrollbar-hide'>
        {invoceData.isLoading ? (
          <div className='container'>
            <div className='mx-auto p-3'>Loading ...</div>
          </div>
        ) : invoceData.rowData.length < 1 ? (
          <div className='container'>
            <div className='mx-auto p-3'>Tidak Ada Invoice</div>
          </div>
        ) : (
          invoceData.rowData.map((row, i) => {
            return (
              <div
                key={row.id}
                className='card mt-2 card-body shadow-all border-none shrink-0'>
                <div className='flex  flex-wrap justify-between'>
                  <span className='flex items-center gap-1 font-normal text-[13px] text-[#BFBFBF]'>
                    <i class="fa-solid fa-clock "></i>
                    <p className='text-[#707070] text-[13px]'>
                      {moment(row.date_of_created).format('DD MMMM YYYY')}
                    </p>
                  </span>
                  <div className='flex items-center gap-1'>
                    <p className="text-[13px]">
                      Overdue
                    </p>
                    <div
                      className={isNegative(parseInt(row.ar_overdue)) ? 'text-[#EA0404] text-[13px] font-medium' : 'text-[13px] font-medium'}
                    >
                      {row.ar_overdue} Hari
                    </div>
                  </div>
                  {/* <div>
                    <span className='text-[#BFBFBF] text-xs '>No. Invoice</span>
                    <br />
                    <span className='font-bold text-sm'>{row.inv_no}</span>
                  </div> */}
                </div>
                <table>
                  <tr>
                    <td className='w-[100px] text-[14px] font-normal'>No. Invoice</td>
                    <td className='w-[10px] text-[14px] font-normal'>:</td>
                    <td>
                      <div className='text-[14px] font-semibold'>
                        {row.inv_no}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className='w-[100px] text-[14px] font-normal'>Status</td>
                    <td className='w-[10px] text-[14px] font-normal'>:</td>
                    <td>
                      <div className='text-[14px] font-semibold'>
                        {row.ar_overdue === 'Done'
                          ? 'Lunas'
                          : row.paid_value !== null
                            ? 'Partial'
                            : 'Belum Bayar'}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className='w-[100px] text-[14px] font-normal'>Total Invoice</td>
                    <td className='w-[10px] text-[14px] font-normal'>:</td>
                    <td>
                      <div className='text-[14px] font-semibold'>
                        {' '}
                        {Number(row.total_invtax).toLocaleString('id-ID', {
                          style: 'currency',
                          currency: 'IDR',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0
                        })}
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            );
          })
        )}
      </div>
      <div className='card-footer  justify-between text-xs'>
        <div className={invoceData.isLoading ? 'invisible' : 'visible'}>
          Tampilkan{' '}
          {invoceData.totalDataPages === 5
            ? (currentInvoicePage - 1) * invoceData.totalDataPages + 1
            : (currentInvoicePage - 1) * 5 + 1}{' '}
          sampai{' '}
          {invoceData.totalDataPages === 5
            ? currentInvoicePage * invoceData.totalDataPages
            : invoceData.totalDataAll}{' '}
          dari {invoceData.totalDataAll}
        </div>
        <div className=''>
          <nav
            aria-label='Page navigation example'
            className={invoceData.rowData.length < 1 ? 'hidden' : ''}>
            <ul className='inline-flex'>
              <li>
                <button
                  className={
                    currentInvoicePage === 1 || invoceData.rowData.length < 1
                      ? 'hidden'
                      : 'p-1'
                  }
                  onClick={() => setCurrentInvoicePage(1)}>
                  <i className='fa-solid fa-backward'></i>
                </button>
              </li>
              <li>
                <button
                  className={
                    currentInvoicePage === 1 || invoceData.rowData.length < 1
                      ? 'hidden'
                      : 'p-1'
                  }
                  onClick={onPrevPage}>
                  <i className='fa-solid fa-chevron-left'></i>
                </button>
              </li>
              <li>
                <button className='p-1'>{currentInvoicePage}</button>
              </li>
              <li>
                <button
                  className={
                    currentInvoicePage === invoceData.totalPages ||
                      invoceData.rowData.length < 1
                      ? 'hidden'
                      : 'p-1'
                  }
                  onClick={onNextPage}>
                  <i className='fa-solid fa-chevron-right'></i>
                </button>
              </li>
              <li>
                <button
                  className={
                    currentInvoicePage === invoceData.totalPages ||
                      invoceData.rowData.length < 1
                      ? 'hidden'
                      : 'p-1'
                  }>
                  <i
                    className='fa-solid fa-forward'
                    onClick={() =>
                      setCurrentInvoicePage(invoceData.totalPages)
                    }></i>
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default DataInvoice;
