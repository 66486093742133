import React from 'react';
import ReactDom from 'react-dom';
import { Provider } from 'react-redux';
import { store } from './app/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Order from './pages/order/Order';
import Dashboard from './pages/dashboard/Dashboard';
import Invoice from './pages/invoice/Invoice';
import PrivateRoute from './components/PrivateRoute';
import NotFound from './pages/ErrorPage/NotFound';
import Login from './pages/login/Login';
import Profile from './pages/profile/Profile';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const container = document.getElementById('root');

ReactDom.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route
            path='/'
            element={
              <PrivateRoute>
                <App />
              </PrivateRoute>
            }
            errorElement={<>Error Cuy</>}>
            <Route index element={<Dashboard />} />
            <Route path='dashboard' element={<Dashboard />} />
            <Route path='order' element={<Order />} />
            <Route path='invoice' element={<Invoice />} />
            <Route path='/profile' element={<Profile />} />
            <Route path='*' element={<NotFound />} />
          </Route>
          <Route path='/login' element={<Login />} />
        </Routes>
        <ToastContainer />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  container
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
