import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPic, picState } from '../features/pic/picSlice';
import { sidebarState, unwarp, wrap } from '../features/sidebar/sidebarSlice';
import { HiUsers } from 'react-icons/hi';

const Topbar = () => {
  const state = useSelector(sidebarState);
  const pic = useSelector(picState);
  const { user } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const values = user.data.branchPIC;

  const menuIconClick = () => {
    state ? dispatch(wrap()) : dispatch(unwarp());
  };

  const handleChange = value => {
    dispatch(setPic(value));
  };

  return (
    <header className='flex items-stretch align-middle  h-14  bg-white shadow'>
      <button className='pl-5' id='nav-button' onClick={menuIconClick}>
        <i className='fa-solid fa-bars'></i>
      </button>
      <div className='self-center ml-auto h-12 flex align-middle mr-5'>
        {values.length > 0 && (
          <div className='flex'>
            <span className='self-center p-2 border-2 rounded-full z-20 bg-white'>
              <HiUsers />
            </span>
            <select
              name=''
              id=''
              className='h-[30px] px-7 self-center ml-[-25px] z-10 rounded-full border-2 text-sm'
              onChange={event => handleChange(event.target.value)}
              value={pic}>
              <option value='null'>
                Semua Data
              </option>
              {values.map(row => {
                return (
                  <option value={row.id} key={row.id}>
                    {row.nama}
                  </option>
                );
              })}
            </select>
          </div>
        )}
        <img
          crossOrigin='anonymous'
          src={`https://ik.imagekit.io/kqzg1tirkb/siberat/logo.svg`}
          alt=''
          className='w-[50px] ml-4'
        />
      </div>
    </header>
  );
};

export default Topbar;
