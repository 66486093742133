import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import {
  passwordUpdate,
  resetUpdatePassword
} from '../features/profile/profileSlice';

const schema = Yup.object().shape({
  password: Yup.string().required('Current password is required'),
  newPassword: Yup.string()
    .required('New password is required')
    .min(8, 'New password must be at least 8 characters'),
  confirmPassword: Yup.string()
    .required('Confirm password is required')
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
});

const PasswordUpdateForm = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });

  const {
    isSuccessUpdatePassword,
    isErrorUpdatePassword,
    isLoadingUpdatePassword,
    messageUpdatePassword
  } = useSelector(state => state.profile);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isErrorUpdatePassword) {
      toast.error(messageUpdatePassword);
    }

    if (isSuccessUpdatePassword) {
      toast.success('Pasword berhasil diperbarui');
      reset();
    }
    dispatch(resetUpdatePassword());
  }, [
    isErrorUpdatePassword,
    isSuccessUpdatePassword,
    dispatch,
    messageUpdatePassword,
    reset
  ]);

  const onSubmit = (data, e) => {
    e.preventDefault();

    dispatch(passwordUpdate(data));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='bg-white rounded-lg p-3'>
      <h2 className='text-md mb-5 font-bold'>Update Password</h2>
      <div className='mb-4'>
        <label
          htmlFor='password'
          className='block text-gray-700 font-medium mb-2'>
          Password
        </label>
        <input
          type='password'
          id='password'
          name='password'
          {...register('password')}
          className={`border rounded-lg py-2 px-3 w-1/2 form-input ${
            errors.password ? 'border-red-500' : ''
          }`}
          placeholder='Masukan Password Saat Ini'
        />
        {errors.password && (
          <p className='text-red-500 text-xs italic'>
            {errors.password.message}
          </p>
        )}
      </div>
      <div className='mb-4'>
        <label
          htmlFor='newPassword'
          className='block text-gray-700 font-medium mb-2'>
          Password Baru
        </label>
        <input
          type='password'
          id='newPassword'
          name='newPassword'
          {...register('newPassword')}
          className={`border rounded-lg py-2 px-3 w-1/2 form-input ${
            errors.newPassword ? 'border-red-500' : ''
          }`}
          placeholder='Masukan Password Baru'
        />
        {errors.newPassword && (
          <p className='text-red-500 text-xs italic'>
            {errors.newPassword.message}
          </p>
        )}
      </div>
      <div className='mb-4 relative'>
        <label
          htmlFor='confirmPassword'
          className='block text-gray-700 font-bold mb-2'>
          Konfirmasi Password
        </label>
        <input
          type='password'
          id='confirmPassword'
          name='confirmPassword'
          {...register('confirmPassword')}
          className={`border rounded-lg py-2 px-3 w-1/2 form-input ${
            errors.confirmPassword ? 'border-red-500' : ''
          }`}
          placeholder='Masukan Konfirmasi Password'
        />

        {errors.confirmPassword && (
          <p className='text-red-500 text-xs italic'>
            {errors.confirmPassword.message}
          </p>
        )}
      </div>
      <div>
        <button
          type='submit'
          className='bg-[#FFA500] text-white font-bold py-2 px-4 rounded'>
          {isLoadingUpdatePassword ? 'Loading...' : 'Update Password'}
        </button>
      </div>
    </form>
  );
};

export default PasswordUpdateForm;
