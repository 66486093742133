import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Modal from '../../components/Modal';
import ExportToCSVButton from '../../components/ExportToCSVButton';
import Pagination from '../../components/pagination/Pagination';
import DetailInvoice from './DetailInvoice';
import Datepicker from 'react-tailwindcss-datepicker';
import { NumericFormat } from 'react-number-format';
import { useSelector } from 'react-redux';
import client from '../../api';
import { picState } from '../../features/pic/picSlice';
import allowedCustomers from '../../data/allowedCustomers';

const Invoice = () => {
  const { user } = useSelector(state => state.auth);
  const pic = useSelector(picState);
  const [invoiceData, setInvoiceData] = useState({
    rowData: [],
    isLoading: false,
    totalPages: 0,
    totalDataAll: 0,
    totalDataPages: 0
  });
  const [sizePage, setSizePage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [isDetailModalOpen, toggleDetailModal] = useState(false);
  const [invoiceDetail, setInvoiceDetail] = useState(null);
  const [statusInvoice, setInvoiceOrder] = useState('');
  const [totalRow, setTotalRow] = useState(0);
  const [search, setSearch] = useState('');
  const [dateFilter, setdateFilter] = useState({
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().endOf('month').format('YYYY-MM-DD')
  });

  const handleDateValueChange = newValue => {
    if (newValue.endDate && newValue.startDate) {
      setdateFilter(newValue);
    }
  };

  const checkStatusInvoice = (paid_value, total_invtax, ar_overdue) => {
    if (paid_value === total_invtax && ar_overdue === 'Done') {
      return <span className='btn bg-[#3E1C86] text-white '>lunas</span>;
    }

    if (!paid_value && ar_overdue <= 0) {
      return <span className='btn bg-[#CD2073] text-white'>Belum Bayar</span>;
    }

    if (!paid_value && ar_overdue > 0) {
      return <span className='btn bg-red-900 text-white'>Overdue</span>;
    }

    if (parseFloat(paid_value) < parseFloat(total_invtax)) {
      return <span className='btn bg-[#FFA500] text-white '>Partial</span>;
    }
  };

  const handleSizePage = newValue => {
    setSizePage(newValue);
    setCurrentPage(1);
  };

  const handleStatus = newValue => {
    setInvoiceOrder(newValue);
    setCurrentPage(1);
  };

  const getData = async (currentPage = 1, sizePage = 10) => {
    const response = await client.get(
      `api/customers/invoice?page=${currentPage}&from=${dateFilter.startDate}&until=${dateFilter.endDate}&size=${sizePage}&invoice_status=${statusInvoice}&search=${search}&branch=${pic}`,
      {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${user.data.token}`
        }
      }
    );
    return response.data;
  };

  useEffect(() => {
    setInvoiceData(prevState => ({
      ...prevState,
      rowData: [],
      isLoading: true
    }));
    getData(currentPage, sizePage).then(info => {
      const {
        count,
        rowCount,
        rows,
        totalPages: total_pages,
        currentPage
      } = info;
      setInvoiceData({
        isLoading: false,
        rowData: rows,
        totalPages: total_pages,
        totalDataPages: rowCount,
        totalDataAll: count
      });
      setTotalRow(count);
      setCurrentPage(currentPage);
    });
  }, [currentPage, dateFilter, statusInvoice, sizePage, search, pic]);

  return (
    <main className='p-4 sm:p-5 space-y-6'>
      <div className='card'>
        <div className='card-body'>
          <div className='flex flex-col sm:flex-row  sm:items-center align-middle justify-between'>
            <div id='head-title' className='active'>
              <i className='fa-solid fa-file-lines'></i>
              <span className='pl-3 text-black'>Invoice</span>
            </div>
            <div className='relative text-gray-600 focus-within:text-gray-400'>
              <span className='absolute inset-y-0 left-0 flex items-center pl-2'>
                <button
                  type='submit'
                  className='p-1 focus:outline-none focus:shadow-outline'>
                  <svg
                    fill='none'
                    stroke='currentColor'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    viewBox='0 0 24 24'
                    className='w-6 h-6'>
                    <path d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z'></path>
                  </svg>
                </button>
              </span>
              <input
                type='text'
                className='py-2 text-xs text-black border-2  rounded-full pl-10  focus:bg-white focus:outline-orange-400'
                placeholder='Cari'
                onChange={e => setSearch(e.target.value)}
              />
            </div>
          </div>
          <div className='flex flex-col sm:flex-row  sm:items-end align-middle justify-between'>
            <div className='text-xs text-gray-400'>
              <span>Periode Tanggal</span>
              <div className='flex mt-2'>
                <span className='inline-flex items-center px-3 text-sm text-gray-900 bg-white rounded-l-md border-r-0 border-2 border-grey-400'>
                  <i className='fa-solid fa-calendar-days'></i>
                </span>
                <Datepicker
                  value={dateFilter}
                  onChange={handleDateValueChange}
                  inputClassName='font-normal bg-green-100  border-2 border-grey-400 dark:bg-green-900 dark:placeholder:text-green-100 w-full'
                  displayFormat={'DD/MM/YYYY'}
                />
              </div>
            </div>
            <div className='flex items-center gap-2 text-gray-400'>
              <span className='text-xs'>Tampilkan:</span>
              <button
                className={`btn ${sizePage === 10 ? `btn-grey` : ``}`}
                onClick={() => handleSizePage(10)}>
                10
              </button>
              <button
                className={`btn ${sizePage === 25 ? `btn-grey` : ``}`}
                onClick={() => handleSizePage(25)}>
                25
              </button>
              <button
                className={`btn ${sizePage === 50 ? `btn-grey` : ``}`}
                onClick={() => handleSizePage(50)}>
                50
              </button>
              <button
                className={`btn ${sizePage === totalRow ? `btn-grey` : ``}`}
                onClick={() => handleSizePage(totalRow)}>
                Semua
              </button>
            </div>
          </div>
          <div className='flex items-center gap-2 text-gray-400 text-xs'>
            <span>Status Invoice:</span>
            <button
              className={`btn ${statusInvoice === '' ? `btn-grey` : ``}`}
              onClick={() => handleStatus('')}>
              Semua Status
            </button>
            <button
              className={`btn ${
                statusInvoice === 'overdue' ? `bg-red-900 text-white` : ``
              }`}
              onClick={() => handleStatus('overdue')}>
              Overdue
            </button>
            <button
              className={`btn ${
                statusInvoice === 'belum_bayar' ? `bg-[#CD2073] text-white` : ``
              }`}
              onClick={() => handleStatus('belum_bayar')}>
              Belum Bayar
            </button>
            <button
              className={`btn ${
                statusInvoice === 'cicil' ? `bg-[#FFA500] text-white` : ``
              }`}
              onClick={() => handleStatus('cicil')}>
              Partial
            </button>
            <button
              className={`btn ${
                statusInvoice === 'lunas' ? `bg-[#3E1C86] text-white` : ``
              }`}
              onClick={() => handleStatus('lunas')}>
              Lunas
            </button>
          </div>
        </div>
      </div>
      {Object.hasOwn(allowedCustomers, user.data.name) && (
        <ExportToCSVButton
          fileName={`invoice-${statusInvoice}`}
          statusInvoice={statusInvoice}
        />
      )}

      <div className='overflow-auto scrollbar-hide'>
        <table className='table-auto w-full'>
          <thead className='p-2 text-[13px] bg-gray-100'>
            <tr>
              <th className='border-y border-slate-400 w-[5%] border-l rounded-tl-lg'>
                No
              </th>
              <th className='border-y border-slate-400'>Tanggal</th>
              <th className='border-y border-slate-400'>No. Invoice</th>
              <th className='border-y border-slate-400'>Sub Total</th>
              <th className='border-y border-slate-400'>PPN</th>
              <th className='border-y border-slate-400'>Asuransi</th>
              <th className='border-y border-slate-400'>Total Invoice</th>
              <th className='border-y border-slate-400'>Due Date</th>
              <th className='border-y border-slate-400'>Status</th>
              <th className='border-y border-slate-400'>AR Overdue</th>
              <th className='border-y w-[5%] border-r  border-slate-400'>
                Rincian
              </th>
            </tr>
          </thead>
          <tbody className='bg-white border border-slate-400 text-xs'>
            {invoiceData.isLoading ? (
              <tr className='text-center'>
                <td colSpan='11'>Loading ...</td>
              </tr>
            ) : invoiceData.rowData.length > 0 ? (
              invoiceData.rowData.map((row, i) => {
                return (
                  <tr
                    className={`align-center border border-slate-300 ${
                      i % 2 === 0 ? `bg-slate-100` : ''
                    }`}
                    key={i}>
                    <td className='text-center'>
                      {(currentPage - 1) * sizePage + 1 + i}
                    </td>
                    <td>{moment(row.inv_tgl).format('DD-MM-YYYY')}</td>
                    <td>{row.inv_no}</td>
                    <td className='text-right'>
                      <NumericFormat
                        value={parseInt(row.total_inv)}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'Rp '}
                        renderText={value => <p>{value}</p>}
                      />
                    </td>
                    <td className='text-right'>
                      <NumericFormat
                        value={parseInt(row.total_tax ?? 0)}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'Rp '}
                        renderText={value => <p>{value}</p>}
                      />
                    </td>
                    <td className='text-right'>
                      <NumericFormat
                        value={parseInt(row.tot_ins ?? 0)}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'Rp '}
                        renderText={value => <p>{value}</p>}
                      />
                    </td>
                    <td className='text-right'>
                      <NumericFormat
                        value={parseInt(row.total_invtax ?? 0)}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'Rp '}
                        renderText={value => <p>{value}</p>}
                      />
                    </td>
                    <td>{moment(row.inv_due).format('DD-MM-YYYY')}</td>
                    <td className='text-center'>
                      {checkStatusInvoice(
                        row.paid_value,
                        row.total_invtax,
                        row.ar_overdue
                      )}
                    </td>
                    <td className='text-center'>{row.ar_overdue}</td>
                    <td className='text-center'>
                      <button
                        className='modal-open hover:bg-gray-400  rounded-full w-6 h-6   '
                        onClick={() => {
                          setOpenModal(true);
                          toggleDetailModal(!isDetailModalOpen);
                          setInvoiceDetail(row);
                        }}>
                        <i className='fa-solid fa-ellipsis'></i>
                      </button>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr className='text-center'>
                <td colSpan='11'>Tidak Ada Data</td>
              </tr>
            )}
          </tbody>
        </table>
        <div className='flex justify-between p-3 rounded-b-lg border border-slate-300 text-xs mt-0'>
          {invoiceData.rowData.length > 0 ? (
            <>
              <div>
                Tampilkan {(currentPage - 1) * sizePage + 1} sampai{' '}
                {invoiceData.totalDataPages === sizePage
                  ? currentPage * invoiceData.totalDataPages
                  : (currentPage - 1) * sizePage +
                    invoiceData.totalDataPages}{' '}
                dari {invoiceData.totalDataAll}
              </div>
              <div>
                <Pagination
                  className='pagination-bar'
                  currentPage={currentPage}
                  totalCount={invoiceData.totalDataAll}
                  pageSize={sizePage}
                  onPageChange={page => setCurrentPage(page)}
                />
              </div>
            </>
          ) : (
            <div>Tampilkan 0 sampai 0 dari 0</div>
          )}
        </div>
      </div>

      {isDetailModalOpen ? (
        <Modal
          open={openModal}
          onClose={() => {
            setOpenModal(false);
            toggleDetailModal(false);
            setInvoiceDetail(null);
          }}
          title={`Rincian Invoice`}
          width='w-10/12'>
          <DetailInvoice
            detailInvoice={invoiceDetail}
            onClose={() => {
              setOpenModal(false);
              toggleDetailModal(false);
              setInvoiceDetail(null);
            }}
          />
        </Modal>
      ) : null}
    </main>
  );
};

export default Invoice;
