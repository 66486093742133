import React, { useState } from 'react';
import client from '../api';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import Papa from 'papaparse';

const ExportToCSVButton = ({ fileName, statusInvoice }) => {
  const { user } = useSelector(state => state.auth);
  const [isLoading, setIsLoading] = useState(false);

  const exportToCSV = async () => {
    setIsLoading(true);

    try {
      const response = await client.get(
        `api/customers/invoice/export?invoice_status=${statusInvoice}`,
        {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${user.data.token}`
          }
        }
      );
      const { data } = response;

      const csvData = Papa.unparse(data);

      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
      saveAs(blob, `${fileName}.csv`);
    } catch (error) {
      toast.error('Terjadi kesalahan');
      console.error('Error exporting to CSV:', error);
    }

    setIsLoading(false);
  };

  return (
    <button
      className={`bg-blue-500 hover:bg-blue-700 text-white text-sm font-bold py-2 px-4 rounded-md ${
        isLoading ? 'opacity-50 cursor-not-allowed' : ''
      }`}
      onClick={exportToCSV}
      title='data di export berdasarkan status invoice'
      disabled={isLoading}>
      {isLoading ? (
        <i className='fa-solid fa-spinner animate-spin mr-1'></i>
      ) : (
        <i className='fa-solid fa-file-excel mr-1'></i>
      )}
      Excel File
    </button>
  );
};

export default ExportToCSVButton;

// import React from 'react';
// import client from '../api';
// import { saveAs } from 'file-saver';
// import { toast } from 'react-toastify';
// import { useSelector } from 'react-redux';
// import Papa from 'papaparse';

// const ExportToCSVButton = ({ fileName, statusInvoice }) => {
//   const { user } = useSelector(state => state.auth);
//   const exportToCSV = async () => {
//     try {
//       const response = await client.get(
//         `api/customers/invoice/export?invoice_status=${statusInvoice}`,
//         {
//           headers: {
//             'Content-type': 'application/json',
//             Authorization: `Bearer ${user.data.token}`
//           }
//         }
//       );
//       const { data } = response;

//       const csvData = Papa.unparse(data);

//       const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
//       saveAs(blob, `${fileName}.csv`);
//     } catch (error) {
//       toast.error('Terjadi kesalahan');
//       console.error('Error exporting to CSV:', error);
//     }
//   };

//   return (
//     <button
//       className='bg-blue-500 hover:bg-blue-700 text-white text-sm font-bold py-2 px-4 rounded-md'
//       onClick={exportToCSV}
//       title='data di export berdasarkan status invoice'>
//       <i class='fa-solid fa-file-excel mr-1'></i>
//       Excel File
//     </button>
//   );
// };

// export default ExportToCSVButton;
