import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import client from '../../api';
import { picState } from '../../features/pic/picSlice';
import 'moment/locale/id';

const DataOrder = () => {
  moment.locale('id')
  const { user } = useSelector(state => state.auth);
  const pic = useSelector(picState);
  const [orderData, setOrderData] = useState({
    rowData: [],
    isLoading: false,
    totalPages: 0,
    totalDataAll: 0,
    totalDataPages: 0
  });
  const [currentOrderPage, setCurrentOrderPage] = useState(1);

  const getData = async (pageNo = 1, pic = null, sizePage = 5) => {
    const response = await client.get(
      `/api/customers/dashboard/order?page=${pageNo}&size=${sizePage}&branch=${pic}`,
      {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${user.data.token}`
        }
      }
    );
    return response.data;
  };

  const onNextPage = () => setCurrentOrderPage(currentOrderPage + 1);
  const onPrevPage = () => setCurrentOrderPage(currentOrderPage - 1);

  useEffect(() => {
    setOrderData(prevState => ({
      ...prevState,
      rowData: [],
      isLoading: true
    }));
    getData(currentOrderPage, pic)
      .then(info => {
        const {
          count,
          rowCount,
          rows,
          totalPages: total_pages,
          currentPage
        } = info;
        setOrderData({
          isLoading: false,
          rowData: rows,
          totalPages: total_pages,
          totalDataPages: rowCount,
          totalDataAll: count
        });
        setCurrentOrderPage(currentPage);
      })
      .catch(() => toast.error('Internal Server Error'));
  }, [currentOrderPage, pic]);
  return (
    <div className='card self-start'>
      <div className='card-header flex justify-between text-sm'>
        <div>Order Berjalan</div>
        {/* <nav
          aria-label='Page navigation example'
          className={orderData.rowData.length < 1 ? 'hidden' : 'p-1'}>
          <ul className='inline-flex'>
            <li>
              <button
                className={
                  currentOrderPage === 1 || orderData.rowData.length < 1
                    ? 'hidden'
                    : 'p-1'
                }
                onClick={() => setCurrentOrderPage(1)}>
                <i className='fa-solid fa-backward'></i>
              </button>
            </li>
            <li>
              <button
                className={
                  currentOrderPage === 1 || orderData.rowData.length < 1
                    ? 'hidden'
                    : 'p-1'
                }
                onClick={onPrevPage}>
                <i className='fa-solid fa-chevron-left'></i>
              </button>
            </li>
            <li>
              <button className='p-1'>{currentOrderPage}</button>
            </li>
            <li>
              <button
                className={
                  currentOrderPage === orderData.totalPages ||
                  orderData.rowData.length < 1
                    ? 'hidden'
                    : 'p-1'
                }
                onClick={onNextPage}>
                <i className='fa-solid fa-chevron-right'></i>
              </button>
            </li>
            <li>
              <button
                className={
                  currentOrderPage === orderData.totalPages ||
                  orderData.rowData.length < 1
                    ? 'hidden'
                    : 'p-1'
                }>
                <i
                  className='fa-solid fa-forward'
                  onClick={() => setCurrentOrderPage(orderData.totalPages)}></i>
              </button>
            </li>
          </ul>
        </nav> */}
      </div>
      <div className='card-body pt-1 overflow-y-auto max-h-96 scroll-smooth scrollbar-hide'>
        {orderData.isLoading ? (
          <div className='container'>
            <div className='mx-auto p-3'>Loading ...</div>
          </div>
        ) : orderData.rowData.length < 1 ? (
          <div className='container'>
            <div className='mx-auto p-3'>Tidak Ada Order Berjalan</div>
          </div>
        ) : (
          orderData.rowData.map((row, i) => {
            return (
              <div
                key={row.order_id}
                className='card mt-2 card-body shadow-all border-none shrink-0'>
                <div className='flex  flex-wrap justify-between'>
                  <span className='flex items-center gap-1 font-normal text-[13px] text-[#BFBFBF]'>
                    <i class="fa-solid fa-clock "></i>
                    <p className='text-[#707070] text-[13px]'>
                      {moment(row.date_of_created).format('DD MMMM YYYY')}
                    </p>
                  </span>
                  <div className='flex items-center gap-1'>
                    <p className="text-[13px]">
                      {row.po_file != null ? 'Berkas Dokumen' : 'Dokumen Belum Upload'}{' '}
                    </p>

                    {row.po_file != null ? <i class="fa-solid fa-circle-check text-[#00AA15]"></i> : <i className='fa-solid fa-circle-info text-[#EA0404]'></i>}
                  </div>
                </div>
                <table>
                  <tr>
                    <td className='w-[100px] text-[14px] font-normal'>No Order</td>
                    <td className='w-[10px] text-[14px] font-normal'>:</td>
                    <td>
                      <div className='text-[14px] font-semibold'>
                        {row.no_order}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className='w-[100px] text-[14px] font-normal'>Status</td>
                    <td className='w-[10px] text-[14px] font-normal'>:</td>
                    <td>
                      <div className='text-[14px] font-semibold'>
                        {row.order_status}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className='w-[100px] text-[14px] font-normal'>Muatan</td>
                    <td className='w-[10px] text-[14px] font-normal'>:</td>
                    <td>
                      <div className='text-[14px] font-semibold'>
                        {row.loads.replace('<br>', '')}
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            );
          })
        )}
      </div>
      <div className='card-footer  justify-between text-xs'>
        <div className={orderData.isLoading ? 'invisible' : 'visible'}>
          Tampilkan{' '}
          {orderData.totalDataPages === 5
            ? (currentOrderPage - 1) * orderData.totalDataPages + 1
            : (currentOrderPage - 1) * 5 + 1}{' '}
          sampai{' '}
          {orderData.totalDataPages === 5
            ? currentOrderPage * orderData.totalDataPages
            : orderData.totalDataAll}{' '}
          dari {orderData.totalDataAll}
        </div>
        <div className=''>
          <nav
            aria-label='Page navigation example'
            className={orderData.rowData.length < 1 ? 'hidden' : ''}>
            <ul className='inline-flex'>
              <li>
                <button
                  className={
                    currentOrderPage === 1 || orderData.rowData.length < 1
                      ? 'hidden'
                      : 'p-1'
                  }
                  onClick={() => setCurrentOrderPage(1)}>
                  <i className='fa-solid fa-backward'></i>
                </button>
              </li>
              <li>
                <button
                  className={
                    currentOrderPage === 1 || orderData.rowData.length < 1
                      ? 'hidden'
                      : 'p-1'
                  }
                  onClick={onPrevPage}>
                  <i className='fa-solid fa-chevron-left'></i>
                </button>
              </li>
              <li>
                <button className='p-1'>{currentOrderPage}</button>
              </li>
              <li>
                <button
                  className={
                    currentOrderPage === orderData.totalPages ||
                      orderData.rowData.length < 1
                      ? 'hidden'
                      : 'p-1'
                  }
                  onClick={onNextPage}>
                  <i className='fa-solid fa-chevron-right'></i>
                </button>
              </li>
              <li>
                <button
                  className={
                    currentOrderPage === orderData.totalPages ||
                      orderData.rowData.length < 1
                      ? 'hidden'
                      : 'p-1'
                  }>
                  <i
                    className='fa-solid fa-forward'
                    onClick={() =>
                      setCurrentOrderPage(orderData.totalPages)
                    }></i>
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default DataOrder;
