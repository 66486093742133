import React, { useEffect } from 'react';

const Modal = props => {
  const {
    open,
    onClose,
    width = 'w-11/12 md:max-w-2xl',
    title,
    children
  } = props;
  useEffect(() => {
    function handleEscapeKey(event: KeyboardEvent) {
      if (event.code === 'Escape') {
        onClose();
      }
    }

    document.addEventListener('keydown', handleEscapeKey);
    return () => document.removeEventListener('keydown', handleEscapeKey);
  }, []);

  if (!open) return null;
  return (
    <div>
      <div className='fixed w-full h-full top-0 left-0 flex items-center justify-center z-30'>
        <div className='modal-overlay absolute w-full h-full bg-gray-900 opacity-50'></div>
        <div
          className={`modal-container bg-white ${width}  mx-auto rounded shadow-lg z-50 overflow-y-auto`}>
          <div className='modal-close absolute top-0 right-0 cursor-pointer flex flex-col items-center mt-4 mr-4 text-white text-sm z-50'></div>
          <div className='modal-content py-4 text-left px-6'>
            <div className='flex justify-between items-center pb-3'>
              <h1 className='text-xl font-extrabold'>{title}</h1>
              <div className='modal-close cursor-pointer'>
                <button
                  onClick={() => {
                    onClose();
                  }}>
                  <svg
                    className='fill-current text-black'
                    xmlns='http://www.w3.org/2000/svg'
                    width='18'
                    height='18'
                    viewBox='0 0 18 18'>
                    <path d='M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z'></path>
                  </svg>
                </button>
              </div>
            </div>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
