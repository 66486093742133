import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import sidebarReducer from '../features/sidebar/sidebarSlice';
import profileReducer from '../features/profile/profileSlice';
import picReducer from '../features/pic/picSlice';

export const store = configureStore({
  reducer: {
    sidebar: sidebarReducer,
    pic: picReducer,
    auth: authReducer,
    profile: profileReducer
  },
  devTools: true
});
