import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: 'null'
};

export const picSlice = createSlice({
  name: 'pic',
  initialState,
  reducers: {
    setPic: (state, action) => {
      state.value = action.payload;
    }
  }
});

export const { setPic } = picSlice.actions;

export const picState = state => state.pic.value;

export default picSlice.reducer;
