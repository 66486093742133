import API from '../../api';
const API_URL = '/api/customers';

const getProfile = async token => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };

  const response = await API.get(`${API_URL}/profile`, config);

  return response.data;
};

const uploadPhoto = async (file, token) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`
    }
  };

  const response = await API.post(
    `${API_URL}/profile/photo/upload`,
    file,
    config
  );

  return response.data;
};

const passwordUpdate = async (data, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };

  const response = await API.post(`${API_URL}/update-password`, data, config);

  return response.data;
};

const profileService = {
  getProfile,
  uploadPhoto,
  passwordUpdate
};

export default profileService;
