function CustomerCard(props) {
  const { profile, handleChangePhoto } = props;

  const isHo = profile.data.accountType === 'HO';

  return (
    <>
      <div className='basis-1/2'>
        <div className='flex align-top gap-4 mt-2'>
          <i className='fa-regular fa-user'></i>

          <div className='self-center'>
            <div className='text-xs'>{isHo ? 'Nama Perusahaan' : 'Nama'}</div>
            <div className='font-semibold text-sm'>
              {isHo ? profile.data.username : profile.data.nama}
            </div>
          </div>
        </div>

        {isHo && (
          <div className='flex align-top gap-4 mt-4'>
            <i className='fa-solid fa-location-dot'></i>

            <div className='self-center'>
              <div className='text-xs'>Alamat</div>
              <div className='font-semibold text-sm'>
                {profile.data.Customer.address}
              </div>
            </div>
          </div>
        )}

        <div className='flex align-top gap-4 mt-4'>
          <i className='fa-solid fa-phone'></i>

          <div className='self-center'>
            <div className='text-xs'>Phone</div>
            <div className='font-semibold text-sm'>
              {isHo ? profile.data.Customer.phone_number : profile.data.nohp}
            </div>
          </div>
        </div>

        <div className='flex align-top gap-4 mt-4'>
          <i className='fa-regular fa-envelope'></i>

          <div className='self-center'>
            <div className='text-xs'>Email</div>
            <div className='font-semibold text-sm'>{profile.data.email}</div>
          </div>
        </div>

        {isHo && (
          <div className='flex align-top gap-4 mt-4'>
            <i className='fa-solid fa-camera'></i>
            <div className='self-center'>
              <div className='text-xs mb-1'>Logo Perusahaan</div>

              <input
                className='form-control
              block
              w-full
              px-3
              py-1.5
              text-base
              font-normal
              text-gray-700
              bg-white bg-clip-padding
              border border-solid border-gray-300
              rounded
              transition
              ease-in-out
              m-0
              focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none'
                type='file'
                id='photo'
                name='photo'
                onChange={e => handleChangePhoto(e.target.files[0])}
              />
            </div>
          </div>
        )}
      </div>

      <div>
        {isHo && (
          <div className='flex align-top gap-4 mt-4'>
            <i className='fa-regular fa-address-card'></i>

            <div className='self-center'>
              <div className='text-xs'>NPWP</div>
              <div className='font-semibold text-sm'>
                {profile.data.Customer.npwp}
              </div>
            </div>
          </div>
        )}

        {isHo && (
          <div className='flex align-top gap-4 mt-4'>
            <i className='fa-regular fa-star'></i>

            <div className='self-center'>
              <div className='text-xs'>Bergabung Sejak</div>
              <div className='font-semibold text-sm'>
                {new Date(profile.data.Customer.created_at).toLocaleString(
                  'id-ID',
                  {
                    month: 'long',
                    year: 'numeric',
                    day: 'numeric'
                  }
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default CustomerCard;
