import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import './App.css';
import './index.css';
import Sidebar from './components/Sidebar';
import Topbar from './components/Topbar';
function App() {
  useEffect(() => {
    document.body.classList.add('bg-[#F7F8FA]');
  });

  return (
    <main className='flex flex-col h-screen'>
      <div className='flex flex-1 overflow-hidden'>
        <Sidebar />
        <div className='flex flex-1 flex-col overflow-y-auto shadow'>
          <Topbar />
          <Outlet />
        </div>
      </div>
    </main>
  );
}

export default App;
