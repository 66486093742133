import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import client from '../../api';
import DataInvoice from './DataInvoice';
import DataOrder from './DataOrder';
import { picState } from '../../features/pic/picSlice';
import ScheduleKapal from './ScheduleKapal';
import { ReactComponent as Logo } from '../../svg/dashboard-icon.svg';

const Dashboard = () => {
  const { user } = useSelector(state => state.auth);
  const pic = useSelector(picState);
  const values = [
    {
      id: 1,
      text: 'Belum Bayar',
      val: 'notpaid',
      class: 'bg-[#CD2073] text-white'
    },
    {
      id: 2,
      text: 'Partial',
      val: 'partial',
      class: 'bg-[#FFA500] text-white'
    },
    { id: 3, text: 'Overdue', val: 'overdue', class: 'bg-red-600 text-white' }
  ];
  const [activeId, setActiveId] = useState(1);
  const [filterInvoice, setFilterInvoice] = useState('notpaid');
  const [outstanding, setOutstanding] = useState({ total: 0, count: 0 });

  const getData = async (pic = null) => {
    const response = await client.get(
      `/api/customers/dashboard/outstanding?branch=${pic}`,
      {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${user.data.token}`
        }
      }
    );
    return response.data;
  };

  useEffect(() => {
    getData(pic)
      .then(value => {
        setOutstanding({
          total: Number(value.data.total_outstanding)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
          count: value.data.count
        });
      })
      .catch(() => toast.error('Internal Server Error'));
  }, [pic]);

  return (
    <main className='p-4 sm:p-5 space-y-6'>
      <div id='head-title' className='active flex  flex-wrap gap-2'>
        <Logo />
        <span className=' text-black'>Dashboard</span>
      </div>
      <div id='notice-info' hidden className='bg-white px-5 py-2 my-5'>
        <span className='text-gray-400 text-xs'>Notice Info</span>
      </div>
      <div className='row-span-1  md:col-auto'>
        <div className='card'>
          <div className='card-body -mb-4'>
            <span className='block text-black text-sm font-bold'>Invoice</span>
          </div>
          <div className='flex justify-between'>
            <div className='card-body  flex gap-3'>
              <div className='text-[13px] font-normal text-[#000000]'>Total :
                <span>
                  <br />
                  Outstanding / Invoice
                </span>
              </div>
              <p className='text-black font-bold text-sm'>
                Rp.{outstanding.total} /
                <span className="pl-1">
                  {outstanding.count}
                </span>
              </p>
            </div>
            <div className='card-body flex'>
              <div className='text-[13px] text-[#000000]'>Pencarian Terkait <span>
                <br /> Invoice
              </span>
              </div>
              <div className='flex gap-4 flex-wrap'>
                {values.map(val => (
                  <div className='' key={val.id}>
                    <button
                      onClick={() => {
                        setActiveId(val.id);
                        setFilterInvoice(val.val);
                      }}
                      className={` ${val.id === 2 ? 'py-2 px-4' : 'p-2'}
                      rounded text-xs
                      ${activeId === val.id
                          ? val.class
                          : 'bg-gray-300 text-black '
                        }`}>
                      {val.text}
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className='grid md:grid-cols-2 gap-6'>
        <DataOrder />
        <DataInvoice filter={filterInvoice} />
      </section>
      <ScheduleKapal />

    </main>
  );
};

export default Dashboard;
