import axios from 'axios';

const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

client.interceptors.response.use(undefined, err => {
  const error = err.response;
  if (401 === error.status && window.location.pathname !== '/login') {
    localStorage.removeItem('sb-customer');
   
    window.location.href = '/login';
  }
  return Promise.reject(err);
});
export default client;
