import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Modal from '../../components/Modal';
import Pagination from '../../components/pagination/Pagination';
import DetailLacak from './DetailLacak';
import FormUploadPo from './FormUploadPo';
import Datepicker from 'react-tailwindcss-datepicker';
import { useSelector } from 'react-redux';
import client from '../../api';
import { NumericFormat } from 'react-number-format';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { picState } from '../../features/pic/picSlice';


const Order = () => {
  const { user } = useSelector(state => state.auth);
  const pic = useSelector(picState);
  const [orderData, setOrderData] = useState({
    rowData: [],
    isLoading: false,
    totalPages: 0,
    totalDataAll: 0,
    totalDataPages: 0
  });
  const [sizePage, setSizePage] = useState(10);
  const [currentOrderPage, setCurrentOrderPage] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [isFormModalOpen, toggleFormModal] = useState(false);
  const [isDetailModalOpen, toggleDetailModal] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const [statusOrder, setStatusOrder] = useState('');
  const [totalRow, setTotalRow] = useState(0);
  const [search, setSearch] = useState('');
  const [refresh, setRefresh] = useState(false);
  const [dateFilter, setdateFilter] = useState({
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().endOf('month').format('YYYY-MM-DD')
  });
  const urlPo = process.env.REACT_APP_PO_URL;
  const handleDateValueChange = newValue => {
    if (newValue.endDate && newValue.startDate) {
      setdateFilter(newValue);
    }
  };

  const handleRefresh = () => {
    setRefresh(!refresh);
  };

  const handleSizePage = newValue => {
    setSizePage(newValue);
    setCurrentOrderPage(1);
  };

  const handleStatus = newValue => {
    setStatusOrder(newValue);
    setCurrentOrderPage(1);
  };

  const getData = async (currentOrderPage = 1, sizePage = 10) => {
    const response = await client.get(
      `/api/customers/orders?page=${currentOrderPage}&from=${dateFilter.startDate}&until=${dateFilter.endDate}&size=${sizePage}&order_status=${statusOrder}&search=${search}&branch=${pic}`,
      {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${user.data.token}`
        }
      }
    );
    return response.data;
  };

  useEffect(() => {
    setOrderData(prevState => ({
      ...prevState,
      rowData: [],
      isLoading: true
    }));
    getData(currentOrderPage, sizePage).then(info => {
      const {
        count,
        rowCount,
        rows,
        totalPages: total_pages,
        currentPage
      } = info;
      setOrderData({
        isLoading: false,
        rowData: rows,
        totalPages: total_pages,
        totalDataPages: rowCount,
        totalDataAll: count
      });
      setTotalRow(count);
      setCurrentOrderPage(currentPage);
    });
  }, [
    currentOrderPage,
    dateFilter,
    statusOrder,
    sizePage,
    search,
    refresh,
    pic
  ]);

  return (
    <main className='p-4 sm:p-5 space-y-6'>
      <div className='card'>
        <div className='card-body'>
          <div className='flex flex-col sm:flex-row  sm:items-center align-middle justify-between'>
            <div id='head-title' className='active'>
              <i className='fa-solid fa-file-lines'></i>
              <span className='pl-3 text-black'>Order</span>
            </div>
            <div className='relative text-gray-600 focus-within:text-gray-400'>
              <span className='absolute inset-y-0 left-0 flex items-center pl-2'>
                <button
                  type='submit'
                  className='p-1 focus:outline-none focus:shadow-outline'>
                  <svg
                    fill='none'
                    stroke='currentColor'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    viewBox='0 0 24 24'
                    className='w-6 h-6'>
                    <path d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z'></path>
                  </svg>
                </button>
              </span>
              <input
                type='text'
                className='py-2 text-xs text-black border-2  rounded-full pl-10  focus:bg-white focus:outline-orange-400'
                placeholder='Cari'
                onChange={e => setSearch(e.target.value)}
              />
            </div>
          </div>
          <div className='flex flex-col sm:flex-row  sm:items-end align-middle justify-between'>
            <div className='text-xs text-gray-400'>
              <span>Periode Tanggal</span>
              <div className='flex mt-2'>
                <span className='inline-flex items-center px-3 text-sm text-gray-900 bg-white rounded-l-md border-r-0 border-2 border-grey-400'>
                  <i className='fa-solid fa-calendar-days'></i>
                </span>
                <Datepicker
                  value={dateFilter}
                  onChange={handleDateValueChange}
                  inputClassName='font-normal bg-green-100  border-2 border-grey-400 dark:bg-green-900 dark:placeholder:text-green-100 w-full'
                  displayFormat={'DD/MM/YYYY'}
                />
              </div>
            </div>
            <div className='flex items-center gap-2 text-gray-400'>
              <span className='text-xs'>Tampilkan:</span>
              <button
                className={`btn ${sizePage === 10 ? `btn-grey` : ``}`}
                onClick={() => handleSizePage(10)}>
                10
              </button>
              <button
                className={`btn ${sizePage === 25 ? `btn-grey` : ``}`}
                onClick={() => handleSizePage(25)}>
                25
              </button>
              <button
                className={`btn ${sizePage === 50 ? `btn-grey` : ``}`}
                onClick={() => handleSizePage(50)}>
                50
              </button>
              <button
                className={`btn ${sizePage === totalRow ? `btn-grey` : ``}`}
                onClick={() => handleSizePage(totalRow)}>
                Semua
              </button>
            </div>
          </div>
          <div className='flex items-center gap-2 text-gray-400 text-xs'>
            <span>Status Order:</span>
            <button
              className={`btn ${statusOrder === 'OTW' ? `bg-orange-200 text-orange-900` : ``
                }`}
              onClick={() => handleStatus('OTW')}>
              OTW
            </button>
            <button
              className={`btn ${statusOrder === 'Completed' ? `bg-green-200 text-green-900` : ``
                }`}
              onClick={() => handleStatus('Completed')}>
              Selesai
            </button>
            <button
              className={`btn ${statusOrder === '' ? `btn-grey` : ``}`}
              onClick={() => handleStatus('')}>
              Semua Status
            </button>
          </div>
        </div>
      </div>
      <div className='overflow-auto scrollbar-hide'>
        <table className='table-auto w-full'>
          <thead className='p-2 text-[13px] bg-gray-100'>
            <tr>
              <th className='border-y border-slate-400 w-[10px] border-l rounded-tl-lg'>
                No
              </th>
              <th className='border-y w-[10%] border-slate-400'>Tanggal</th>
              <th className='border-y w-[10%] border-slate-400'>No. Order</th>
              <th className='border-y border-slate-400'>Muatan</th>
              <th className='border-y border-slate-400'>Lokasi Muat</th>
              <th className='border-y border-slate-400'>Lokasi Bongkar</th>
              <th className='border-y w-[10%] border-slate-400'>Harga (Rp)</th>
              <th className='border-y border-slate-400 w-[10%]'>Lead Time</th>
              <th className='border-y border-slate-400'>Status</th>
              <th className='border-y border-slate-400 w-[10%]'>Dokumen</th>
              <th className='border-y w-[5%] border-r  border-slate-400'>
                Lacak
              </th>
            </tr>
          </thead>
          <tbody className='bg-white border border-slate-400 text-xs'>
            {orderData.isLoading ? (
              <tr className='text-center'>
                <td colSpan='10'>Loading ...</td>
              </tr>
            ) : orderData.rowData.length > 0 ? (
              orderData.rowData.map((row, i) => {
                var startDate = moment(row?.date_of_loadingplan);
                var endDate = moment(row?.tgl_est_tiba);
                var result = endDate.diff(startDate, 'days')
                return (
                  <tr
                    className={`align-top border border-slate-300 ${i % 2 === 0 ? `bg-slate-100` : ''
                      }`}
                    key={i}>
                    <td >{(currentOrderPage - 1) * sizePage + 1 + i}</td>
                    <td>
                      {moment(row.date_of_loadingplan).format('DD-MM-YYYY')}
                    </td>
                    <td>
                      {row.no_order}
                      {row.inv_no ? (
                        <Tippy
                          content={<span className='text-xs'>No. Invoice</span>}
                          placement='left'>
                          <div className='flex mt-2'>
                            <div>
                              <i className='fa-solid fa-share-nodes'></i>
                            </div>
                            <span className='ml-3'>{row.inv_no}</span>
                          </div>
                        </Tippy>
                      ) : (
                        ''
                      )}
                    </td>
                    <td>
                      <div dangerouslySetInnerHTML={{ __html: row.loads }} />
                    </td>
                    <td>
                      {row.loading_place}
                      <br />
                      <div className='mt-5'>
                        <span className='text-gray-400'>Waktu Muat: </span>
                        {row.date_of_loadingplan
                          ? moment(row.date_of_loadingplan).format('DD-MM-YYYY')
                          : '-'}
                      </div>
                    </td>
                    <td>
                      {row.unloading_place}
                      <br />
                      <div className='mt-5'>
                        <span className='text-gray-400'>Waktu Bongkar: </span>
                        {row.date_of_completed
                          ? moment(row.date_of_completed).format('DD-MM-YYYY')
                          : '-'}
                      </div>
                    </td>
                    <td>
                      <NumericFormat
                        value={parseInt(row.costs ?? 0)}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'Rp '}
                        renderText={value => <p>{value}</p>}
                      />
                    </td>
                    <td className='text-center'>
                      {result == 0 ? '1' : result} - {parseInt(result) + 3} hari
                    </td>
                    <td className='text-center'>
                      <div
                        className={`ml-2 mr-2 rounded-lg py-2 px-3 text-xs font-semibold  ${row.order_status === 'OTW'
                          ? `bg-orange-200 text-orange-900`
                          : `bg-green-200 text-green-900`
                          }`}>
                        {row.order_status === 'Completed' ? 'Selesai' : 'OTW'}
                      </div>
                    </td>
                    <td className='text-center'>
                      {row.po_number ? (
                        <a
                          href={`${urlPo}/${row.po_file}`}
                          target='_blank'
                          rel='noreferrer'
                          className='block p-2 text-blue-900 rounded-lg text-xs hover:bg-blue-400 hover:text-white font-semibold bg-blue-200 '>
                          PO : {row.po_number}
                        </a>
                      ) : (
                        <div>
                          <button
                            className='text-left bg-[#C0BBE0] p-2 text-blue-900 rounded-lg text-xs hover:bg-blue-900 hover:text-white font-semibold focus:outline-none'
                            onClick={() => {
                              setOpenModal(true);
                              toggleFormModal(!isFormModalOpen);
                              setOrderId(row.order_id);
                            }}>
                            Upload PO
                          </button>
                        </div>
                      )}
                    </td>
                    <td>
                      <Tippy
                        content={
                          <span className='text-xs'>
                            Lihat detail checkpoint
                          </span>
                        }
                        placement='top'>
                        <button
                          className='modal-open hover:bg-gray-400  rounded-full w-10 h-10 focus:outline-none'
                          onClick={() => {
                            setOpenModal(true);
                            toggleDetailModal(!isDetailModalOpen);
                            setOrderId(row);
                          }}>
                          <i className='fa-solid fa-location-dot'></i>
                        </button>
                      </Tippy>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr className='text-center'>
                <td colSpan='11'>Tidak Ada Data</td>
              </tr>
            )}
          </tbody>
        </table>
        <div className='flex justify-between p-3 rounded-b-lg border border-slate-300 text-xs mt-0'>
          {orderData.rowData.length > 0 ? (
            <>
              <div>
                Tampilkan {(currentOrderPage - 1) * sizePage + 1} sampai{' '}
                {orderData.totalDataPages === sizePage
                  ? currentOrderPage * orderData.totalDataPages
                  : (currentOrderPage - 1) * sizePage +
                  orderData.totalDataPages}{' '}
                dari {orderData.totalDataAll}
              </div>
              <div>
                <Pagination
                  className='pagination-bar'
                  currentPage={currentOrderPage}
                  totalCount={orderData.totalDataAll}
                  pageSize={sizePage}
                  onPageChange={page => setCurrentOrderPage(page)}
                />
              </div>
            </>
          ) : (
            <div>Tampilkan 0 sampai 0 dari 0</div>
          )}
        </div>
      </div>
      {isFormModalOpen ? (
        <Modal
          open={openModal}
          onClose={() => {
            setOpenModal(false);
            toggleFormModal(false);
            setOrderId(null);
          }}
          title='Form Upload PO'>
          <FormUploadPo
            orderId={orderId}
            onClose={() => {
              setOpenModal(false);
              toggleFormModal(false);
              setOrderId(null);
            }}
            refresh={() => handleRefresh()}
          />
        </Modal>
      ) : null}

      {isDetailModalOpen ? (
        <Modal
          open={openModal}
          onClose={() => {
            setOpenModal(false);
            toggleDetailModal(false);
            setOrderId(null);
          }}
          title='Lacak'>
          <DetailLacak
            detailOrder={orderId}
            onClose={() => {
              setOpenModal(false);
              toggleDetailModal(false);
              setOrderId(null);
            }}
          />
        </Modal>
      ) : null}
    </main>
  );
};

export default Order;
