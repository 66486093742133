import API from '../../api';
const API_URL = '/api/customers';

const login = async userData => {
  const response = await API.post(`${API_URL}/login`, userData);

  if (response.data) {
    localStorage.setItem('sb-customer', JSON.stringify(response.data));
  }

  return response.data;
};

const logout = async () => {
  localStorage.removeItem('sb-customer');
};

const authService = {
  login,
  logout
};

export default authService;
