import React, { useEffect, useState } from 'react';
import moment from 'moment-with-locales-es6';
import { useSelector } from 'react-redux';
import client from '../../api';
// import Modal from '../../component/Modal';
// import DetailDriver from './DetailDriver';
import 'react-photo-view/dist/react-photo-view.css';
import { PhotoProvider, PhotoView } from 'react-photo-view';

const DataOrderSingle = ({ order_id }) => {
  const { user } = useSelector(state => state.auth);
  const [checkPoint, SetCheckPoint] = useState([]);

  const getCheckpoint = async () => {
    const response = await client.get(`/api/customers/checkpoint/${order_id}`, {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${user.data.token}`
      }
    });
    SetCheckPoint(response.data);
  };

  useEffect(() => {
    getCheckpoint();
  }, []);

  return (
    <div>
      <nav className='text-left  mt-2 mb-5' aria-label='Progress'>
        <ol className='space-y-4 overflow-hidden'>
          <li className='relative '>
            {checkPoint.length > 0 ? (
              checkPoint.map((row, i) => (
                <div
                  className='flex flex-grow justify-start space-x-4 px-3'
                  key={i}>
                  <div className='w-[100%]'>
                    <div
                      className='items-center justify-center text-black text-xs tracking-wide'
                      key={i}>
                      <div className='flex items-start gap-2 -ml-3'>
                        <img src='check_trac.png' alt='' />
                        <div className='flex items-center gap-2'>
                          <span className='text-xs font-semibold mt-1.5'>
                            {moment(row.tanggal)
                              .locale('id')
                              .format('dddd, ll')}
                            {/* <span className=' bg-[#00D647] text-white px-1 py-0 text-center'>
                                      Surat Jalan 1
                                    </span>
                                    <span className=' bg-[#FFA500] text-white px-1 py-0 text-center'>
                                      Foto Muat
                                    </span> */}
                          </span>
                        </div>
                      </div>
                      <div className='border-dashed border-gray-400 border-l-2 pb-3'>
                        <div className='ml-5 '>
                          <div className='pt-2'>
                            {row.checkpoint_image && (
                              <PhotoProvider>
                                {row?.checkpoint_image?.split(',')?.map((row, index) => {
                                  return (
                                    <PhotoView key={index} src={`${process.env.REACT_APP_CHECKPOINT_STORAGE}/${row.replaceAll(" ", "")}`}>
                                      <button
                                        className='text-white font-semibold  p-1 pl-2 pr-2 rounded'
                                        style={{
                                          display: index == 0 ? 'block' : 'none',
                                          background: ' #FFA500'
                                        }}>Foto Bongkar</button>
                                    </PhotoView>
                                  )
                                })}
                              </PhotoProvider>
                            )}

                          </div>
                          <div className='pt-2'>{row.posisi}</div>
                          {row.desc !== null && (
                            <div className='flex items-start gap-2 mt-2'>

                              <span>Catatan:</span>
                              <span className='flex justify-between'>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: row.desc ? row.desc : '-'
                                  }}
                                />
                              </span>
                            </div>

                          )}

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className='block bg-neutral-100 p-2 text-center font-sm rounded'>
                <h1> Belum ada data checkpoint</h1>
              </div>
            )}
          </li>
        </ol>
      </nav>
    </div>
  );
};

export default DataOrderSingle;
