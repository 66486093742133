import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { login, reset } from '../../features/auth/authSlice';

function Login() {
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });

  const { email, password } = formData;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user, isLoading, isSuccess, isError, message } = useSelector(
    state => state.auth
  );

  const onChange = e => {
    setFormData(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
  };

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }

    if (isSuccess || user) {
      toast.success('Login successfull');
      navigate('/');
    }

    dispatch(reset());
  }, [isError, message, isSuccess, user, navigate, dispatch]);

  const onSubmit = e => {
    e.preventDefault();

    const user = { email, password };

    dispatch(login(user));
  };

  return (
    <>
      <div className='h-screen  sm:bg-white lg:bg-[#F5F5F5]'>
        <header>
          <div className='flex justify-between px-5 pt-5'>
            <div className='my-auto w-[50px] md:w-[75px]'>
              <img crossOrigin="anonymous"
                src='https://ik.imagekit.io/kqzg1tirkb/siberat/logo.svg'
                alt='Logo Siberat'
                className='h-full w-full object-cover'
              />
            </div>
            {/* <div className="my-auto dropdown inline-block relative">
                        <button className="">
                            <img src="./img/icon.png" alt="Icon Globe" className="h-full w-full object-cover" />
                        </button>
                        <ul className="dropdown-menu absolute hidden bg-white text-black  pt-1 ml-[-140px]">
                            <li><a href="#" className="hover:bg-[#FFA500] block p-2">English</a></li>
                            <li><a href="#" className="hover:bg-[#FFA500] block p-2">Bahasa Indonesia</a></li>
                    </div> */}
          </div>
        </header>
        <section className='flex item-center justify-center bg-white max-w-[1000px] mx-auto mt-10 md:mt-auto content-center'>
          <div
            id='image-login'
            className='hidden md:flex  flex-initial w-2/4 self-center'>
            <img crossOrigin="anonymous"
              src='https://ik.imagekit.io/kqzg1tirkb/siberat/img-login.png'
              alt=''
              className='w-full'
            />
          </div>
          <div id='form-login' className='flex-initial m-auto font-poppins p-5'>
            <div id='title' className=' flex justify-center align-middle'>
              <div className='text-l my-auto'>
                Customer
                <span className='block text-right'>SiBerat</span>
              </div>
              <div className='text-[40px] font-bold align-center my-auto pl-2'>
                Login
              </div>
            </div>
            <div id='desc' className='text-center text-sm my-2'>
              Come on, you just need to login to enter
            </div>
            <div className='text-sm'>
              <form onSubmit={onSubmit} autoComplete='off'>
                <label
                  className='font-medium block mb-1 mt-6 text-gray-700'
                  htmlFor='username'>
                  Email
                </label>
                <input
                  className='appearance-none border-2 rounded-lg w-full py-3 px-3 leading-tight bg-white border-black  focus:outline-none focus:border-[#FFA500] focus:bg-white text-gray-700 pr-16 font-poppins'
                  id='email'
                  name='email'
                  value={email}
                  onChange={onChange}
                  autoFocus
                  placeholder='Enter Your Email'
                  required
                  type='email'
                />
                <label
                  className='font-medium block mb-1 mt-6 text-gray-700'
                  htmlFor='password'>
                  Password
                </label>
                <div className='relative w-full'>
                  <div className='absolute inset-y-0 right-0 flex items-center px-2'>
                    <input
                      className='hidden js-password-toggle'
                      id='toggle'
                      type='checkbox'
                    />
                    {/* <label
                      className=' rounded px-2 py-1 text-sm text-gray-600 font-poppins cursor-pointer js-password-label '
                      htmlFor='toggle'>
                      <i className='fa-solid fa-eye toggle-password'></i>
                    </label> */}
                  </div>
                  <input
                    className='appearance-none border-2 rounded-lg w-full py-3 px-3 leading-tight border-black bg-white bg-gray-100 focus:outline-none focus:border-[#FFA500] focus:bg-white text-gray-700 pr-16 font-poppins js-password'
                    id='password'
                    name='password'
                    value={password}
                    onChange={onChange}
                    placeholder='Enter Your Password'
                    type='password'
                    required
                  />
                </div>
                <button
                  className='w-full bg-[#FFA500] hover:bg-[#ffc252] text-white font-medium py-3 px-4 mt-10 rounded-lg focus:outline-none focus:shadow-outline'
                  type='submit'>
                  Sign in
                </button>
                {/* <div className='text-center'>
                  <a
                    href='forgot_password/step-1.html'
                    className='text-red-500 text-center'>
                    Forgot Password ?
                  </a>
                </div> */}
              </form>
            </div>
          </div>
        </section>
        <footer className='absolute z-2 bottom-0 w-screen'>
          <div className='m-5 border-t-4 text-xs'>
            &copy; {new Date().getFullYear()} PT. SiBerat Digital Logistik, All
            Rights Reserved.
          </div>
        </footer>
      </div>
    </>
  );
}

export default Login;
