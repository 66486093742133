import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  profileCustomer,
  uploadCustomerPhoto,
  resetUpload,
  reset
} from '../../features/profile/profileSlice';
import CustomerCard from '../../components/CustomerCard';
import PasswordUpdateForm from '../../components/PasswordUpdateForm';
import { toast } from 'react-toastify';
import { Tab } from '@headlessui/react';

function Profile() {
  const { profile, isSuccess, isSuccessUpload, isErrorUpload, messageUpload } =
    useSelector(state => state.profile);

  const { user } = useSelector(state => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) navigate('/login');

    dispatch(profileCustomer());
  }, [dispatch, navigate, user]);

  useEffect(() => {
    if (isErrorUpload) {
      toast.error(messageUpload);
    }

    if (isSuccessUpload) {
      toast.success('Logo berhasil disimpan');
      dispatch(reset());
      dispatch(resetUpload());
      dispatch(profileCustomer());
    }
  }, [isErrorUpload, messageUpload, isSuccessUpload, dispatch]);

  const handleChangePhoto = file => {
    if (!file.name.match(/\.(jpg|jpeg|png)$/)) {
      toast.error('Format Tidak didukung');
      return false;
    }

    const formData = new FormData();
    formData.append('photo', file);

    dispatch(uploadCustomerPhoto(formData));
  };

  return (
    <>
      <main className='flex flex-col h-screen'>
        <div className='bg-white px-5 py-3 m-5 p-4 sm:p-5 space-y-6 rounded-lg'>
          <Tab.Group>
            <Tab.List>
              <Tab className='mr-5'>
                {({ selected }) => (
                  /* Use the `selected` state to conditionally style the selected tab. */
                  <div id='head-title' className={selected ? 'active' : ''}>
                    <i className='fa-solid fa-user'></i>
                    <span className='pl-3 text-black'>Profile</span>
                  </div>
                )}
              </Tab>

              <Tab className='mr-5'>
                {({ selected }) => (
                  /* Use the `selected` state to conditionally style the selected tab. */
                  <div id='head-title' className={selected ? 'active' : ''}>
                    <i className='fa-solid fa-user-gear'></i>
                    <span className='pl-3 text-black'>Akun</span>
                  </div>
                )}
              </Tab>
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel>
                <div className='flex flex-col md:flex-row gap-4'>
                  {isSuccess ? (
                    <CustomerCard
                      profile={profile}
                      handleChangePhoto={handleChangePhoto}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </Tab.Panel>
              <Tab.Panel>
                <PasswordUpdateForm />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </main>
    </>
  );
}

export default Profile;
