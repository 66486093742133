import React from 'react';
import DataCheckpoint from './DataCheckpoint';

const DetailLacak = ({ detailOrder, onClose }) => {

  return (
    <div>
      <div className='mt-4 mb-3'>
        <div className='flex items-center gap-2'>
          <span className='text-xs'>No. Order &nbsp; :</span>
          <span className='text-sm font-semibold'>{detailOrder.no_order}</span>
        </div>

        <div className='flex items-center gap-2 text-xs mt-3'>
          <span>Status &nbsp; &nbsp; &nbsp; &nbsp;:</span>
          <span
            className={`rounded-lg py-2 px-3 text-xs font-semibold ${detailOrder.order_status === 'OTW'
              ? `bg-orange-200 text-orange-900`
              : `bg-green-200 text-green-900`
              }`}>
            {detailOrder.order_status === 'Completed' ? 'Selesai' : 'OTW'}
          </span>
        </div>
      </div>
      <div className='scroll-smooth scrollbar-hide overflow-y-auto max-h-72'>
        <DataCheckpoint order_id={detailOrder.order_id} />
      </div>
      <div className='flex justify-end py-3'>
        <div className='modal-close cursor-pointer'>
          <button
            onClick={() => {
              onClose();
            }}
            className='btn border py-2 px-3 bg-slate-100 hover:bg-slate-300 text-md  border-slate-400 '>
            Tutup
          </button>
        </div>
      </div>
    </div>
  );
};

export default DetailLacak;
