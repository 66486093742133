import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { sidebarState } from '../features/sidebar/sidebarSlice';
import { logout, reset } from '../features/auth/authSlice';
import { reset as resetProfile } from '../features/profile/profileSlice';
import { toast } from 'react-toastify';
import { BsFillGridFill } from 'react-icons/bs';
import { BiDollarCircle } from 'react-icons/bi';
import { CgProfile } from 'react-icons/cg';
import { setPic } from '../features/pic/picSlice';
import { LuClipboardList } from "react-icons/lu";
const Sidebar = () => {
  const state = useSelector(sidebarState);
  const [companyLogo, setCompanyLogo] = useState();
  const { user } = useSelector(state => state.auth);
  const { profile, isSuccessUpload } = useSelector(state => state.profile);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sidebar, setSidebar] = useState(
    'hidden ease-in-out duration-300 sm:flex sm:flex-col shrink-0 w-52 items-center min-h-screen shadow bg-white'
  );

  const onLogout = () => {
    dispatch(reset());
    dispatch(logout());
    dispatch(resetProfile());
    dispatch(setPic(null));
    toast.success('Log out Successful');
    navigate('/');
  };

  useEffect(() => {
    setSidebar(
      state
        ? 'hidden ease-in-out duration-300 sm:flex sm:flex-col shrink-0 w-52 items-center min-h-screen shadow bg-white'
        : 'hidden ease-in-out duration-300 sm:flex sm:flex-col shrink-0 w-52 items-center min-h-screen shadow bg-white mini-sidebar'
    );

    setCompanyLogo(profile?.data.Customer.photo);
  }, [state, isSuccessUpload, profile?.data.Customer.photo]);

  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split('/');
  const urlLogo = process.env.REACT_APP_LOGO_URL;

  return (
    <aside className={sidebar} id='sidebar'>
      <img
        crossOrigin='anonymous'
        src={
          companyLogo || user.data.foto
            ? `${urlLogo}/${companyLogo || user.data.foto}`
            : `https://ik.imagekit.io/kqzg1tirkb/siberat/logo.svg`
        }
        alt=''
        className='p-2 mt-5 w-[100px]'
      />
      <span className='font-bold text-sm mt-4 mb-2 '>{user.data.name}</span>
      {/* <span>2020</span> */}
      <div className='flex-grow flex-row justify-between w-full'>
        <ul className='flex flex-col w-full m-auto mt-16 overflow-y-auto p-2'>
          <li>
            <NavLink
              to='/dashboard'
              className={({ isActive }) =>
                isActive || splitLocation[1] === ''
                  ? 'block hover:bg-orange-200  hover:rounded-lg  pl-8 py-3 active'
                  : 'block hover:bg-orange-200  hover:rounded-lg  pl-8 py-3 '
              }>
              {/* <i className='fa-solid fa-house w-5 text-center'></i> */}
              <BsFillGridFill className='w-5 text-center inline scale-125' />
              <span className='pl-3 '>Dashboard</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to='/order'
              className={({ isActive }) =>
                isActive
                  ? 'block hover:bg-orange-200  hover:rounded-lg  pl-8 py-3 active'
                  : 'block hover:bg-orange-200  hover:rounded-lg  pl-8 py-3 '
              }>
              <LuClipboardList className='w-5 text-center inline scale-125' />
              <span className='pl-3'>Order</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to='/invoice'
              className={({ isActive }) =>
                isActive
                  ? 'block hover:bg-orange-200  hover:rounded-lg  pl-8 py-3 active'
                  : 'block hover:bg-orange-200  hover:rounded-lg  pl-8 py-3 '
              }>
              <BiDollarCircle className='w-5 text-center inline scale-150' />
              <span className='pl-3'>Invoice</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to='/profile'
              className={({ isActive }) =>
                isActive
                  ? 'block hover:bg-orange-200  hover:rounded-lg  pl-8 py-3 active'
                  : 'block hover:bg-orange-200  hover:rounded-lg  pl-8 py-3'
              }>
              <CgProfile className='w-5 text-center inline scale-125' />
              <span className='pl-3 '>Profile</span>
            </NavLink>
          </li>
        </ul>
        <div className='flex flex-col w-52 justify-center justify-items-center absolute bottom-0 p-2'>
          {state ? (
            <button
              className='hover:bg-orange-200 hover:rounded-lg py-3'
              type='button'
              onClick={onLogout}>
              <i className='fa-solid fa-right-from-bracket'></i>
              <span className='pl-3'>Log out</span>
            </button>
          ) : (
            <div
              onClick={onLogout}
              className={
                'cursor-pointer hover:bg-orange-200 hover:rounded-lg py-3 w-12 text-center'
              }>
              <span className=''>
                <i className='fa-solid fa-right-from-bracket'></i>
              </span>
            </div>
          )}
        </div>
      </div>
    </aside>
  );
};
export default Sidebar;
